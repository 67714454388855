import "./welcome.css";
const Welcome = () => {
  return (
    <div className="pps__welcome" id="welcome">
       
      <div className="pps__welcome-container">
      <div id ="background-image">
      
      </div>  
        <div className="pps__welcome-title">
          <h1 className= "gradient__text3">YOUR BUSINESS DESERVES THE CARE OF A SPECIALIST</h1>
        </div>
        <div className="pps__welcome__text">
          <p>
            Marketing solutions for professionals in the inland empire and
            beyond.
          </p>
        </div>
        <div className="pps__welcome__button-container">
          <button type="submit" className = "pps__welcome-button" >Learn More</button>
        </div>
      </div>
    </div>
  );
};

export default Welcome;
