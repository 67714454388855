import React from "react";
import "./card.css";
const Card = ({ title, description, icon }) => {
  return (
    <div className="pps__card .noHover  ">
      <div className="pps__card-container">
        <div className="pps__card-icon ">{icon}</div>
        <div className ='pps-card-title'>
          <h1 >{title}</h1>
          </div>
        <div className=" card-description">
          <p>{description}</p>
        </div>
      </div>
    </div>
  );
};

export default Card;
