import React from "react";
import { Cta, Navbar } from "./components";
import { Welcome } from "./containers";
import "./App.css";
import Cards from "./containers/cards/Cards";
import Footer from "./containers/footer/Footer";

const App = () => {
  return (
    <div className="App">
      <Navbar />
      <Welcome />
      <div className="gradient__background">
        <Cards />
        <div className="pps__cta-container-app">
          <Cta />
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default App;
