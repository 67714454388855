import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faPhone } from "@fortawesome/free-solid-svg-icons";
import "./cta.css";
const Cta = () => {
  return (
    <div className="pps__cta">
      <div className="pps__cta-container">
        <div className="pps__cta-title-container">
          <h1 className="pps__cta-title  ">Contact Us Today!</h1>
        </div>
        <div classname="pps__cta-component-container">
          <div className="pps__cta-component">
            <div className="pps__cta-icon cta-email">
              <a
                href="mailto: Support@premierprocessingservice.com<"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon icon={faEnvelope} />
              </a>
            </div>
          </div>
          <div className="pps__cta-component">
            <div className="pps__cta-icon">
              <a
                href="tel:5558920234"
                onclick="ga('send', 'event', { eventCategory: 'Contact', eventAction: 'Call', eventLabel: 'Mobile Button'});"
              >
                <FontAwesomeIcon icon={faPhone} />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Cta;
